//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AboveTheFold from '~/components/AboveTheFold.vue';

export default {
  name: 'Workouts4_AboveTheFold',
  components: {
    AboveTheFold,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
};
